<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn color="secondary" outlined @click="goBack"> <v-icon left>arrow_back</v-icon> Go Back</v-btn>
            <!-- <v-btn color="primary" to="/session"> View All Sessions </v-btn> -->
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table :headers="headers" :items="messages" :search="search" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer :loading="loading">
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatdate }}
            </template>
          </v-data-table>
          <div class="text-center">
            <v-pagination  v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Messages',
  data: () => ({
    search: '',
    headers: [
      { text: 'Customer Input', value: 'input' },
      { text: 'Response', value: 'response' },
      { text: 'Date', value: 'createdAt' }
    ],
    loading: false,
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    payload: { perPage: 10, page: 1, sessionId: 0 },
    messages: []
  }),
  mounted () {
    this.triggerGetSessionMessages()
  },
  watch: {
    page () {
      this.payload.page = this.page
      this.triggerGetSessionMessages()
    }
  },
  methods: {
    ...mapActions({ getSessionMessages: 'botsession/getSessionMessages' }),
    triggerGetSessionMessages () {
      this.loading = true
      this.messages = []
      this.payload.sessionId = this.$route.params.id
      this.getSessionMessages(this.payload).then(response => {
        this.messages = response.data.data
        this.pageCount = response.data.totalPages
        this.loading = false
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        this.loading = false
      })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
