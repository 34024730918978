<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-col cols="6" smm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table :headers="headers" :items="menus" :search="search">
            <template v-slot:[`item.actions`]="{ item }">
                  <v-menu right bottom>
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon  v-bind="attrs" v-on="on">
                      <v-icon color="accent">mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                        <v-list-item  @click="showEditDialog(item)">
                            <v-list-item-title>Edit Media</v-list-item-title>
                        </v-list-item>
                        <v-list-item  @click="showEditMessageDialog(item)">
                            <v-list-item-title>Edit Message</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Update Menu Media</span>
        </v-card-title>
        <v-card-text>
         <v-form v-model="valid" ref="updateform" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-autocomplete
                    :items="mediaTypes"
                    label="Media Type"
                    v-model="mediaType"
                    :rules="mediaTypeRules"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8" v-if="mediaType !== 'LOCATION'">
                <v-file-input chips v-model="media" label="Media"></v-file-input>
              </v-col>
              <v-col cols="12" sm="8" md="8" v-else>
                <v-text-field v-model="location" label="Location Link"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
         </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="editDialog=false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="triggerSaveMedia" :loading="loading"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog v-model="editMessageDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Update Menu Message</span>
        </v-card-title>
        <v-card-text>
         <v-form v-model="valid" ref="updateform" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-textarea label="Menu Message" v-model="menu.message" auto-grow></v-textarea>
              </v-col>
            </v-row>
          </v-container>
         </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="editMessageDialog=false"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="triggerSaveMenu" :loading="loading"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Menu',
  data: () => ({
    search: '',
    headers: [
      { text: 'Id', value: 'id' },
      { text: 'Menu', value: 'message' },
      { text: 'Media', value: 'media.mediaUrl' },
      { text: 'Description', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    menu: {},
    editDialog: false,
    loading: false,
    mediaType: '',
    media: null,
    location: '',
    mediaTypes: ['VIDEO', 'LOCATION', 'CONTACTS', 'BROCHURE'],
    mediaTypeRules: [v => !!v || 'Media Type is required'],
    mediaFileRules: [v => !!v || 'Media file is required'],
    valid: true,
    editMessageDialog: false
  }),
  computed: mapState({
    menus: state => state.menu.menus
  }),
  methods: {
    ...mapActions({ editMenuMedia: 'menu/editMenuMedia', editMenu: 'menu/editMenu' }),
    showEditDialog (item) {
      this.editDialog = true
      this.menu = item
    },
    triggerSaveMedia () {
      if (this.$refs.updateform.validate()) {
        this.loading = true
        const formData = new FormData()
        formData.append('menuId', this.menu.id)
        formData.append('mediaType', this.mediaType)
        formData.append('location', this.location)
        formData.append('file', this.media)
        this.editMenuMedia(formData).then(response => {
          this.loading = false
          this.editDialog = false
          this.mediaType = ''
          this.location = ''
          this.media = null
          this.message = ''
          this.$store.dispatch('menu/getMenus')
          this.$store.dispatch('alert/displayAlert', { snackbar: false, color: 'success', message: response.data.message }, { root: true })
        }).catch(error => {
          this.loading = false
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        })
      }
    },
    showEditMessageDialog (item) {
      this.editMessageDialog = true
      this.menu = item
    },
    triggerSaveMenu () {
      if (this.$refs.updateform.validate()) {
        this.loading = true
        this.editMenu(this.menu).then(response => {
          this.loading = false
          this.editMessageDialog = false
          this.$store.dispatch('menu/getMenus')
          this.$store.dispatch('alert/displayAlert', { snackbar: false, color: 'success', message: response.data.message }, { root: true })
        }).catch(error => {
          this.loading = false
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        })
      }
    }
  },
  created () {
    this.$store.dispatch('menu/getMenus')
  }
}
</script>
