<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>
            <v-btn @click="triggerDownload" color="primary" :loading="exportLoading" v-if="can('CAN_EXPORT_SESSION')">Export </v-btn>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            </v-col>
          </v-card-title>
          <v-card-text>
          <v-data-table :headers="headers" :items="sessions" :search="search" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer :loading="loading">
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | formatdate }}
              </template>
              <template v-slot:[`item.chat.phoneNumber`]="{ item }">
                <router-link :to="`/session/${item.id}/messages`">{{item.chat.phoneNumber}}</router-link>
             </template>
          </v-data-table>
          </v-card-text>
            <div class="text-center">
            <v-pagination  v-model="page" :length="pageCount"></v-pagination>
            </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Session',
  data: () => ({
    search: '',
    headers: [
      { text: 'Id', value: 'id' },
      { text: 'Phone Number', value: 'chat.phoneNumber' },
      { text: 'Created At', value: 'createdAt' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    loading: false,
    exportLoading: false,
    page: 1,
    itemsPerPage: 10,
    pageCount: 0,
    payload: { perPage: 10, page: 1 },
    sessions: []
  }),
  watch: {
    page () {
      this.payload.page = this.page
      this.triggerGetSessions()
    }
  },
  methods: {
    ...mapActions({ exportSessions: 'botsession/exportSessions', getSessions: 'botsession/getSessions' }),
    triggerGetSessions () {
      this.loading = true
      this.sessions = []
      this.getSessions(this.payload).then(response => {
        this.sessions = response.data.data
        this.pageCount = response.data.totalPages
        this.loading = false
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        this.loading = false
      })
    },
    triggerDownload () {
      this.exportLoading = true
      this.exportSessions().then(response => {
        this.exportLoading = false
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        const yourDate = new Date()
        link.download = `Sessions_${yourDate.toISOString().split('T')[0]}.csv`
        link.click()
      }).catch(error => {
        this.exportLoading = false
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
      })
    }
  },
  created () {
    this.triggerGetSessions(this.payload)
  }
}
</script>
