<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn
                color="primary"
                dark
                class="mb-2"
                @click="dialog = true"
              >Add Choice
            </v-btn>
            <v-spacer></v-spacer>
            <v-col cols="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            </v-col>
          </v-card-title>
          <v-data-table :headers="headers" :items="menuchoices" :search="search">
              <template v-slot:[`item.actions`]="{ item }">
                  <v-btn @click="deleteChoice(item)" class="red--text"> Delete </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" permanent max-width="500px">
            <v-card>
                <v-card-title class="primary--text"> Add Choice </v-card-title>
                <v-card-text>
                    <v-text-field v-model="message" label="Message" required></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialog = false" outlined color="primary"> Cancel </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="createNewChoice"> Add </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'MenuChoice',
  data: () => ({
    search: '',
    headers: [
      { text: 'Id', value: 'id' },
      { text: 'Message', value: 'message' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    dialog: false,
    message: ''
  }),
  methods: {
    ...mapActions({ addMenuChoice: 'menu/addMenuChoice', deleteMenuChoice: 'menu/deleteMenuChoice' }),
    createNewChoice () {
      const request = { menuId: this.$route.params.id, message: this.message }
      this.addMenuChoice(request).then(response => {
        this.dialog = false
        this.message = ''
        this.$store.dispatch('menu/getMenuChoices', this.$route.params.id)
        this.$store.dispatch('alert/displayAlert', { snackbar: false, color: 'success', message: response.data.message }, { root: true })
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
      })
    },
    deleteChoice (item) {
      this.deleteMenuChoice(item).then(response => {
        this.dialog = false
        this.$store.dispatch('menu/getMenuChoices', this.$route.params.id)
        this.$store.dispatch('alert/displayAlert', { snackbar: false, color: 'success', message: response.data.message }, { root: true })
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
      })
    }
  },
  computed: mapState({
    menuchoices: state => state.menu.menuchoices
  }),
  mounted () {
    this.$store.dispatch('menu/getMenuChoices', this.$route.params.id)
  },
  created () {
    this.$store.dispatch('menu/getMenuChoices', this.$route.params.id)
  }
}
</script>
