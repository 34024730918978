<template>
  <v-container fluid>
    <v-row align="center" justify="center">
       <v-col cols="12" sm="3">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> {{ securedCount }}  </h3>
          <p class="pa-3 primary--text"> Secured Sessions </p>
          <v-btn text class="pb-4" to="/secured_sessions"> View More </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> {{ unSecuredCount }}  </h3>
          <p class="pa-3 primary--text"> Un Secured Sessions </p>
          <v-btn text class="pb-4" to="/unsecured_sessions"> View More </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> {{ inquiryCount }}  </h3>
          <p class="pa-3 primary--text"> Inquiry Sessions </p>
          <v-btn text class="pb-4" to="/inquiry_sessions"> View More </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> {{ aboutUsCount }}  </h3>
          <p class="pa-3 primary--text"> About Us Sessions </p>
          <v-btn text class="pb-4" to="/aboutus_sessions"> View More </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
       <v-col cols="12" sm="4">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> {{ sessionCount }}  </h3>
          <p class="pa-3 primary--text"> Total Sessions </p>
          <v-btn text class="pb-4" to="/session"> View More </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Home',
  data: () => ({
    loading: true
  }),
  computed: mapState({
    sessionCount: state => state.botsession.sessionCount,
    securedCount: state => state.dash.securedCount,
    unSecuredCount: state => state.dash.unSecuredCount,
    aboutUsCount: state => state.dash.aboutUsCount,
    inquiryCount: state => state.dash.inquiryCount
  }),
  created () {
    this.$store.dispatch('botsession/getSessionCount')
    this.$store.dispatch('dash/getSecuredCount')
    this.$store.dispatch('dash/getUnSecuredCount')
    this.$store.dispatch('dash/getAboutUsCount')
    this.$store.dispatch('dash/getInquiryCount')
  }
}
</script>
